<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="activities-table">
            <thead class="activities-table__header">
              <tr>
                <th scope="col" class="activities-table__header-col">Nom</th>
                <th scope="col" class="activities-table__header-col">Details</th>
                <th scope="col" class="activities-table__header-col">Date</th>
              </tr>
            </thead>
            <tbody class="activities-table__body">
              <tr
                class="activities-table__row"
                v-for="(activity, index) in activities"
                :key="index"
              >
                <td class="activities-table__row-cel">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        v-if="activity.user.avatar"
                        class="h-10 w-10 rounded-full"
                        :src="imageUrl(activity.user.avatar)"
                        :alt="`${activity.user.firstname + ' ' + activity.user.lastname}`"
                      />
                      <div v-else
                        class="h-10 w-10 rounded-full bg-primary flex justify-center items-center"
                      >
                        <span class="uppercase text-white text-base font-semibold tracking-widest">{{activity.user.firstname[0] + activity.user.lastname[0]}}</span>
                      </div>
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ activity.user.firstname }} {{activity.user.lastname}}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ activity.user.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td class="activities-table__row-cel">
                  <span>
                    {{ activity.details }}
                  </span>
                </td>
                <td class="activities-table__row-cel text-sm text-gray-500">
                  {{ moment(activity.createdAt).format("ddd MMM DD, YYYY") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <molecule-pagination 
      v-if="pageInfos" 
      @pageChange="getPageData($event)" 
      :pageInfos="pageInfos"
      :currentPage="currentPage"
    ></molecule-pagination>
  </div>
</template>

<script setup>
import MoleculePagination from "@/components/molecules/MoleculePagination.vue";
import moment from 'moment';
moment.locale('fr');
import { ref, onBeforeMount, computed, inject, onUnmounted } from "vue";
import {useStore} from "vuex";
const {dispatch, state} = useStore();
const activities = computed( () => state.activity.activityLogs?.data);
const pageInfos = computed( () => state.activity.activityLogs?.meta);
const currentPage = ref(1);
const fetchActivities = async () => {
  await dispatch('activity/getAll', currentPage.value);
}
const getPageData = async (page) => {
  currentPage.value = page;
  await fetchActivities();
}
onBeforeMount( async () => {
  await fetchActivities();
});

const imageUrl = inject('imageUrl');

</script>

<style lang="scss" scoped>
.activities-table {
  @apply min-w-full divide-y divide-gray-200;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
