<template>
  <block-dashboard-stats v-if="stats?.length" :stats="stats"/>
  <block-activity-table class="mt-5"/>
</template>

<script setup>
  import BlockDashboardStats from '@/components/blocks/BlockDashboardStats.vue';
  import BlockActivityTable from '@/components/blocks/tables/BlockActivityTable.vue';
  import { onBeforeMount, computed} from 'vue';
  import {useStore} from "vuex";
  const {state, dispatch} = useStore();
  const stats = computed( () => state.activity.stats);
  const logs = computed( () => state.activity.activityLogs);
  onBeforeMount(() => {
    const domains = ['users','posts','comments'];
    const format= 'MM-YYYY';
    domains.forEach( async (domain) => {
      try {
        await dispatch('activity/getStats', {
          domain,
          format
        })
      } catch (error) {
        console.log(error);
      }
    });
  });
</script>

<style lang="scss" scoped></style>
