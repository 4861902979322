<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="dashboard-stats">
    <h3 class="dashboard-stats__heading">Les 30 derniers jours</h3>
    <dl class="dashboard-stats__container">
      <div 
        v-for="(item, index) in stats" 
        :key="index" 
        class="dashboard-stats__item">
        <dt>
          <div class="dashboard-stats__item-icon">
            <component :is="getMeta(item.domain).icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p class="dashboard-stats__item-title">{{ getMeta(item.domain).title }}</p>
        </dt>
        <dd v-if="item.stats?.length" class="dashboard-stats__item-body">
          <p class="dashboard-stats__item-value">
            {{ item.stats[0].count }}
          </p>
            <p v-if="item.stats?.length > 1" :class="[getChangeType(item.stats) == 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-center text-sm font-semibold']">
              <ArrowSmUpIcon v-if="item.changeType === 'increase'" class="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
              <ArrowSmDownIcon v-else class="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
              <span class="sr-only"> {{ getChangeType(item.stats) === 'increase' ? 'Increased' : 'Decreased' }} by </span>
              {{ Math.abs(item.stats[0].count - item.stats[1].count) }}
            </p>
          <div class="dashboard-stats__item-footer">
            <div class="text-sm">
              <router-link :to="getMeta(item.domain).href" class="dashboard-stats__item-footer-link">
                Voir tout<span class="sr-only"> {{ getMeta(item.domain).title}} stats</span></router-link
              >
            </div>
          </div>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/vue/solid'
import { ChatAltIcon, PencilAltIcon, UsersIcon, ThumbUpIcon } from '@heroicons/vue/outline'

const getMeta = (domain) => {
  switch(domain){
    case "users":
      return {
        title:"Nombre d'utilisateurs",
        icon:UsersIcon,
        href:{name:'DashboardUsers'}
      };
    case "posts":
      return {
        title:"Nombre de publications",
        icon:PencilAltIcon,
        href:{name:'ForumHome'}
      };
    case "reactions":
      return {
        title:"Nombre de réactions",
        icon:ThumbUpIcon,
        href:{name:'ForumHome'}
      };
    case "comments":
      return {
        title:"Nombre de commentaires",
        icon:ChatAltIcon,
        href:{name:'ForumHome'},
      };
    default:
      return {
        title:"Nombre d'utilisateurs",
        icon:UsersIcon,
        href:{name:'DashboardUsers'}
      };
  }
}
const getChangeType = (stats) => {
  return stats[0].count > stats[1].count ? 'increase' : 'decrease';
}
const props = defineProps({
  stats:{
    type:Array,
    required:true
  }
});
</script>
<style lang="scss" scoped>
  .dashboard-stats{
    @apply mt-10;
    &__heading{
      @apply text-lg leading-6 font-medium text-gray-900;
    }
    &__container{
      @apply mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3;
    }
    &__item{
      @apply relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden;
      &-icon{
        @apply absolute bg-primary rounded-md p-3;
      }
      &-title{
        @apply ml-20 text-sm font-medium text-gray-500 truncate;
      }
      &-body{
        @apply ml-20 pb-6 flex items-baseline sm:pb-7;
      }
      &-value{
        @apply text-xl font-semibold text-gray-900;
      }
      &-footer{
        @apply absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6;
        &-link{
          @apply font-medium text-primary hover:text-primary/90;
        }
      }
    }
  }
</style>